import { DefaultEffects, getTheme, mergeStyles, PrimaryButton, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useRecoilState } from 'recoil';
import { aIsAudioDeviceSetVisible } from 'routes/LandingPage/atom';
import { useShowAttachmentPanel, useWindowWidth } from 'shared-state/display-options/hooks';
import { useIsVisitorAtLandingPage } from 'shared-state/location/hook';
import { ICallActionButtonProps } from './CallActionButton';

const CallActionButtonView: React.FC<ICallActionButtonProps> = ({
    action,
    onPressed,
    micIsMuted,
    cameraIsMuted,
    sharingIsOn,
    statsAreVisible,
    isLocalUserHandRaised,
    isChatVisible
}) => {
    const [isAttachmentPanelVisible] = useShowAttachmentPanel();
    const theme = getTheme();

    const iconButtonStylesSelected = mergeStyles({
        borderRadius: 4,
        padding: 8,
        position: 'relative',
        cursor: 'pointer',
        userSelect: 'none',
        border: '1px solid var(--stroke-gray-100, #C9CAC8)',
        background: 'var(--stroke-gray-50, #E4E4E3)',
        boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
        selectors: {
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                border: '1px solid var(--stroke-gray-100, #C9CAC8)'
            },
            ':active': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                border: '1px solid var(--stroke-gray-100, #C9CAC8)'
            }
        }
    });

    const iconButtonStylesDefault = mergeStyles({
        borderRadius: 4,
        padding: 8,
        position: 'relative',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        userSelect: 'none',
        border: '1px solid transparent',
        selectors: {
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                border: '1px solid transparent'
            },
            ':active': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                border: '1px solid transparent'
            }
        }
    });

    const actionLabelStyles = mergeStyles([
        {
            backgroundColor: theme.palette.white,
            borderRadius: 4,
            pointerEvents: 'none',
            bottom: -16,
            width: 'auto',
            whiteSpace: 'nowrap',
            padding: '4px 8px',
            boxShadow: DefaultEffects.elevation16,
            right: 34,
            color: theme.palette.black
        }
    ]);

    const getButtonStyles = (actionKey: string, micIsMuted?: boolean, cameraIsMuted?: boolean, sharingIsOn?: boolean) => {
        if (
            (actionKey === 'toggle-audio' && !micIsMuted) ||
            (actionKey === 'toggle-video' && !cameraIsMuted) ||
            (actionKey === 'share-screen' && sharingIsOn) ||
            (actionKey === 'toggle-stats' && statsAreVisible) ||
            (actionKey === 'toggle-attachments' && isAttachmentPanelVisible) ||
            (actionKey === 'raise-hand' && isLocalUserHandRaised) ||
            (actionKey === 'toggle-chat' && isChatVisible)
        ) {
            return iconButtonStylesSelected;
        }
        return iconButtonStylesDefault;
    };

    const { smallDevice } = useWindowWidth();
    const [isVisitorAtLandingPage] = useIsVisitorAtLandingPage();
    const [, setIsAudioDeviceSetVisible] = useRecoilState<boolean>(aIsAudioDeviceSetVisible);

    const handleClick = (providedAction) => {
        const { key } = providedAction;
        if (key === 'device-settings' && isVisitorAtLandingPage) {
            setIsAudioDeviceSetVisible(false);
        }
        onPressed(providedAction);
    };

    return (
        <PrimaryButton
            className={getButtonStyles(action.key, micIsMuted, cameraIsMuted, sharingIsOn)}
            key={action.key}
            onClick={() => handleClick(action)}
            styles={{ root: { minWidth: '40px', marginTop: 4, marginBottom: 4 } }}
            data-test-id={action.key + '-action'}
        >
            {isVisitorAtLandingPage ? (
                <Stack className={'hoverable'} horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 6 }}>
                    <IC variant={action.iconVariant || 'dark'} size={24}>
                        {action.icon}
                    </IC>
                    <Text variant="medium">{IvicosStrings.callActionButtonSelectBackground}</Text>
                </Stack>
            ) : (
                <Stack className={'hoverable'} verticalAlign="center" horizontalAlign="center" style={{ maxWidth: '40px' }}>
                    <IC variant={action.iconVariant || 'dark'} size={24}>
                        {action.icon}
                    </IC>
                    {!smallDevice && (
                        <Stack className={actionLabelStyles + ' display--on-parent-hover'} style={{ position: 'absolute' }}>
                            <Text>{action.title}</Text>
                        </Stack>
                    )}
                </Stack>
            )}
        </PrimaryButton>
    );
};

export default CallActionButtonView;
